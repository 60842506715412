<template>
  <button class="btn" :class="buttonClasses" :style="styleObj" :disabled="isLoading" @click="clickButton">
    <span class="text" :class="textClasses">{{ buttonText }}</span>
    <span :class="{'gachi-sm-loader': loadingSize === 'sm', 'gachi-loader': loadingSize !== 'sm'}" v-if="isLoading"></span>
  </button>
</template>
<script>
export default {
  name: 'LoadingButton',
  props: {
    buttonText: String,
    isLoading: Boolean,
    clickFunc: Function,
    buttonClasses: Array,
    textClasses: Array,
    loadingSize: String,
    styleObj: Object,
  },
  components: {},
  methods: {
    clickButton(){
      if(this.clickFunc instanceof Function) {
        this.clickFunc();
      }
    }
  }
}
</script>
